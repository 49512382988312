<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!equipment">
      <span class="loader"></span>
    </span>
    <div v-if="equipment" class="kw-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("EQUIPMENTS.DETAILS_VIEW_EQUIPMENT") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("CONFIGURATION.EQUIPMENTS_LIST") }}</span>
              </li>
              <li>
                <span>{{ equipment.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button
            class="delete"
            @click="deleteEquipment"
            v-if="$currentUserCan($permissions.PERM_DELETE_EQUIPMENTS)"
          >
            <i class="far fa-trash-alt"></i>
            <span>{{ $t("COMMON.DELETE_INFOS") }}</span>
          </button>
          <button
            class="edit"
            @click="editEquipment"
            v-if="$currentUserCan($permissions.PERM_EDIT_EQUIPMENTS)"
          >
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav kw-tabs"
        value="equipment"
      >
        <tab-pane title="equipment" id="1" :active="true">
          <span slot="title">
            {{ $t("EQUIPMENTS.VIEW_EQUIPMENT") }}
          </span>
          <equipment-view-global
            :equipment="equipment"
            @onContractUpdated="get"
          />
        </tab-pane>
      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import EquipmentViewGlobal from "../partials/EquipmentViewGlobal.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    EquipmentViewGlobal,
  },

  mixins: [],

  props: {
    equipmentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      equipment: null,
    };
  },

  computed: {},

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("equipments/get", this.equipmentId);
        this.equipment = this.$store.getters["equipments/equipment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    editEquipment() {
      this.$emit("onEditEquipment", this.equipment);
    },

    deleteEquipment() {
      this.$emit("onDeleteEquipment", this.equipment);
    },
  },
};
</script>
