<template>
  <div
    v-if="
      $currentUserCan($permissions.PERM_VIEW_ANY_EQUIPMENT_CATEGORIES) &&
      equipmentCategory
    "
  >
    <router-link :to="$objectViewRoute(equipmentCategory)">
      {{ `${equipmentCategory.name}` }}
    </router-link>
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";

export default {
  name: "equipment-category",

  components: {},

  mixins: [formMixin],

  props: {
    equipmentCategory: {
      type: Object,
      default: () => ({}),
      description: "Equipment Category",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {
    equipmentCategory(equipmentCategory) {},
  },
};
</script>
