var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.sendInformation.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[_c('div',{staticClass:"add-form-inner"},[_c('div',{staticClass:"form-group-wrapper-item title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("EQUIPMENTS.EQUIPMENT_INFORMATIONS")))])]),_c('div',{staticClass:"form-wrapper full"},[(
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS) &&
            !_vm.hideOrganization
          )?_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ORGANIZATION')} (*)`,"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.equipment.organization?.id,"filterable":true,"showAll":false,"disabled":!!_vm.equipment.id},on:{"organizationChanged":(organizationId) => {
                _vm.equipment.organization.id = organizationId;
                _vm.onFormChanged();
              }}})],1):_vm._e(),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1),_c('div',{staticClass:"form-group-wrapper full"},[_c('div',{staticClass:"form-group-wrapper-item"},[_c('image-selector',{attrs:{"label":_vm.$t('COMMON.PICTURE'),"defaultImage":_vm.equipment.icon,"ressource_name":"equipments","ressource_id":_vm.equipment.id ? _vm.equipment.id : 0,"field":"icon"},on:{"imageChanged":(file_url) => {
                _vm.equipment.icon = file_url;
                _vm.onFormChanged();
              }}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.icon}})],1)]),_c('div',{staticClass:"form-group-wrapper-item full"},[_c('base-input',{attrs:{"label":`${_vm.$t('EQUIPMENTS.EQUIPMENT_NAME')} (*)`},on:{"change":() => {
              _vm.onFormChanged();
            }},model:{value:(_vm.equipment.name),callback:function ($$v) {_vm.$set(_vm.equipment, "name", $$v)},expression:"equipment.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-group-wrapper-item full"},[_c('base-input',{attrs:{"label":`${_vm.$t(`EQUIPMENTS.EQUIPMENT_CATEGORY_NAME`)} (*)`}},[_c('equipment-category-selector',{attrs:{"equipmentCategory":_vm.equipment.category?.id,"filterable":true,"showAll":false,"filterOrganization":_vm.equipment.organization?.id},on:{"equipmentCategoryChanged":(equipmentCategory) => {
                _vm.equipment.category.id = equipmentCategory;
                _vm.onFormChanged();
              }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.category}})],1),_c('div',{staticClass:"form-group-wrapper-item full"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.COMMENT'),"placeholder":_vm.$t('COMMON.COMMENT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.equipment.excerpt),callback:function ($$v) {_vm.$set(_vm.equipment, "excerpt", $$v)},expression:"equipment.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-group-wrapper-item full"},[_c('el-checkbox',{model:{value:(_vm.equipment.show_as_filter),callback:function ($$v) {_vm.$set(_vm.equipment, "show_as_filter", $$v)},expression:"equipment.show_as_filter"}},[_vm._v(_vm._s(_vm.$t("CONFIGURATION.SHOW_AS_FILTER")))])],1)]),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn cancel",attrs:{"type":"button"},on:{"click":_vm.onCloseEquipmentModal}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CANCEL"))+" ")]),_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading || _vm.processing}},[(_vm.loading || _vm.processing)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.equipment.id ? _vm.$t("COMMON.EDIT") : _vm.$t("COMMON.ADD_ITEM"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }